import { useDispatch, useSelector } from 'react-redux';
import {
  create as createFavorite,
  destroy as destroyFavorite,
} from 'src/explore/data/account_favorite_products/actions';

const useFavorite = ( productId, recommId ) => {
  const isFavorite = useSelector( state => state.data.accountFavoriteProducts[productId]);
  const visitorId = useSelector( state => state.data.account?.meta?.visitor_id );
  const account = useSelector( state => state.data.account?.data );
  const dispatch = useDispatch();

  const identity = account?.hashed_id || visitorId;

  const toggleFavorite = () => {
    if ( isFavorite ) {
      dispatch( destroyFavorite( productId ));
    } else {
      dispatch( createFavorite( productId, identity, recommId, account ));
    }
  };

  return [ isFavorite, toggleFavorite ];
};

export default useFavorite;
